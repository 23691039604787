module services {
    export module tariff {
        export class revenueAllocationCodeService implements interfaces.tariff.IRevenueAllocationCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all TariffCountry for a dropdown/autocomplete list
            getDropdownList(declarationCountryId: number, taxTypeId: number, refundTypeId: number, searchText: string, numRecords: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RevenueAllocationCode/GetAllocationCodesDropDown", {
                    declarationCountryId: declarationCountryId,
                    taxTypeId: taxTypeId,
                    refundTypeId: refundTypeId,
                    searchText: searchText,
                    numRecords: numRecords
                });
            }
        }
    }
    angular.module("app").service("revenueAllocationCodeService", services.tariff.revenueAllocationCodeService);
}